import * as React from 'react'
import {useState, useEffect} from 'react'
import axios from 'axios'
import {StaticImage} from "gatsby-plugin-image";
import Header from '../partials/header'
import Footer from '../partials/footer'
import AruzySeo from '../components/aruzySeo.js'
import VoucherFilters from '../components/VoucherFilters';
import HeaderTitle from "../components/HeaderTitle";
import Voucher from "../components/Voucher";

const CategoryPage = (props, data) => {
    const [vouchers, setVouchers] = useState(false)
    const [parentpage, setParentPage] = useState(true)
    const [Rerender, setRerender] = useState(false)
    const [filter, setFilter] = useState(false)
    const [Filterdata, setFilterdata] = useState(false)

    useEffect(() => {
        axios.get(`${process.env.API_URL}/vouchers?category.slug=${props.pageContext.categorySlug}`).then((res) => {
            setVouchers(res.data);
            return res.data;
        });
    }, [props.pageContext.categorySlug])

    function noResults() {
        return (
            <div className='text-center pt-20'>
                <h4>No Vouchers Found!</h4>
                <p>Maybe try adjusting your search to be a little more generic...</p>
            </div>
        )
    }

    function handleChange(newValue) {
        setParentPage(newValue)
    }

    function handleClick(event) {
        // toggle class on click
        let filterOp = document.querySelectorAll(".filtericon");
        let datatype = event.currentTarget.getAttribute('data-type');
        if (datatype === "all") {
            [].forEach.call(filterOp, function (el) {
                if (el.getAttribute('data-type') !== "all") {
                    el.classList.remove("active");
                }
            });
            let elems = document.querySelectorAll(".filterAccordionSection .accordion__item");
            [].forEach.call(elems, function (el) {
                el.classList.remove("hide");
            });
            event.currentTarget.classList.add('active');
            setRerender(!Rerender)
        } else {
            event.currentTarget.classList.toggle('active');
            [].forEach.call(filterOp, function (el) {
                let datatype1 = el.getAttribute('data-type');
                let optionClass = document.getElementsByClassName(datatype1 + 'Options');
                if (optionClass.length !== 0) {
                    el.classList.contains('active') ? optionClass[0].classList.remove("hide", "noBorder") : optionClass[0].classList.add("hide", "noBorder");
                }
            });
            setRerender(!Rerender)
            document.getElementsByClassName('allfilter')[0].classList.remove("active");
        }
    }

    function renderResults() {
        if (vouchers === false) {
            return 'loading..'
        }
        if (vouchers.length === 0) {
            return noResults()
        }
        vouchers.sort(function (a, b) {
            const sortA = a.sortBy, sortB = b.sortBy, nameA = a.brand.name.trim(), nameB = b.brand.name.trim();
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
        return vouchers.map((voucher) => {
            if (voucher.published_at != null) {
                return <Voucher key={voucher.id} voucher={voucher} filter={false}/>
            }
        })
    }

    function renderFilteredData(data) {
        if (data !== undefined) {
            setFilterdata(data.map((r) => {
                return <Voucher voucher={r} filter={true}/>
            }))
        }
    }

    return (
        <main>
            <AruzySeo title={props.pageContext.categoryName}/>
            <Header/>
            <div className='cat-page topattachbgsection pt-30 mb-30'>
                <div className="container">
                    <div className="col-md-12 text-center">
                        <HeaderTitle title={props.pageContext.categoryName}/>
                    </div>
                </div>
                <div className='container'>
                    <div className='row text-right'>
                        <div className='filterButton'>
                            <button className='btn mb-3' style={{color: 'rgb(255, 60, 124)'}} onClick={() => setFilter(!filter)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26.201" height="26" viewBox="0 0 26.201 26">
                                    <g id="Group_278" data-name="Group 278" transform="translate(-20 -135)">
                                        <g id="Group_403" data-name="Group 403">
                                            <path id="Path_29" data-name="Path 29" d="M411.653,157.371a5.811,5.811,0,1,0,5.812,5.811,5.818,5.818,0,0,0-5.812-5.811" transform="translate(-371.264 -22.371)" fill="#fc3571"/>
                                            <path id="Path_30" data-name="Path 30"
                                                  d="M400.9,163.514a8.655,8.655,0,0,1,.7-3.424.69.69,0,0,0-.087-.015h-6.964a5.342,5.342,0,0,0-5.337,5.335v12.96a5.341,5.341,0,0,0,5.337,5.334h13.931a5.339,5.339,0,0,0,5.335-5.334v-6.48a.987.987,0,0,0-.241-.638,8.691,8.691,0,0,1-12.678-7.738"
                                                  transform="translate(-369.221 -22.703)" fill="#fc3571"/>
                                        </g>
                                    </g>
                                </svg>
                                <span>Filter</span>
                            </button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-1 mobilenone'>
                            <div className='filtericon active allfilter' data-type="all" onClick={handleClick}>
                                <div className="iconcolor">
                                    <StaticImage src="../images/newimage/search.png" alt="Search"/>
                                </div>
                                <span>All</span>
                            </div>
                            <div className="filtericon" data-type="brand" onClick={handleClick}>
                                <div className="iconcolor">
                                    <StaticImage src="../images/newimage/share.png" alt="Search"/>
                                </div>
                                <span>BRANDS</span>
                            </div>
                            <div className="filtericon" data-type="offer" onClick={handleClick}>
                                <div className="iconcolor">
                                    <StaticImage src="../images/newimage/cart.png" alt="Search"/>
                                </div>
                                <span>OFFERS</span>
                            </div>
                            <div className="filtericon" data-type="location" onClick={handleClick}>
                                <div className="iconcolor">
                                    <StaticImage src="../images/newimage/location.png" alt="Search"/>
                                </div>
                                <span>LOCATION</span>
                            </div>
                            <div className="filtericon" data-type="tag" onClick={handleClick}>
                                <div className="iconcolor">
                                    <StaticImage src="../images/newimage/tag.png" alt="Search"/>
                                </div>
                                <span>TAGS</span>
                            </div>
                        </div>
                        <div className={`col-md-3 filter ${filter ? 'filterShow' : ''}`}>
                            <VoucherFilters
                                onClick={handleChange}
                                onChange={renderFilteredData}
                                searchBy={'category'}
                                categoryid={props.pageContext.categoryId.replace('Category_', '')}
                                current_page={parentpage}
                                Rerender={Rerender}
                                excludeList={[]}
                                allVouchers={props.pageContext.vouchers}
                            />
                        </div>
                        <div className='col-md-8' id='filterParent'>
                            {!parentpage && <div className='row'>{renderResults()}</div>}
                            {parentpage && <div className='row' id='filteredData'> {Filterdata}</div>}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default CategoryPage
